import React from 'react'


const Mail = props => (
	<svg {...props} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 469.2 469.2"
	>
<g>
	<path d="M22.202,77.023C25.888,75.657,29.832,74.8,34,74.8h401.2c4.168,0,8.112,0.857,11.798,2.224L267.24,246.364
		c-18.299,17.251-46.975,17.251-65.28,0L22.202,77.023z M464.188,377.944c3.114-5.135,5.012-11.098,5.012-17.544V108.8
		c0-4.569-0.932-8.915-2.57-12.899L298.411,254.367L464.188,377.944z M283.2,268.464c-13.961,11.961-31.253,18.027-48.6,18.027
		c-17.347,0-34.64-6.06-48.6-18.027L20.692,391.687c4.094,1.741,8.582,2.714,13.308,2.714h401.2c4.726,0,9.214-0.973,13.308-2.714
		L283.2,268.464z M2.571,95.9C0.932,99.885,0,104.23,0,108.8V360.4c0,6.446,1.897,12.409,5.012,17.544l165.777-123.577L2.571,95.9z"
	/>
</g>
		<g>
</g>
		<g>
</g>
		<g>
</g>
		<g>
</g>
		<g>
</g>
		<g>
</g>
		<g>
</g>
		<g>
</g>
		<g>
</g>
		<g>
</g>
		<g>
</g>
		<g>
</g>
		<g>
</g>
		<g>
</g>
		<g>
</g>
</svg>
)

export default Mail
