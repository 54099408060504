import React from 'react'



const Rocket = props => (
	<svg {...props}>
		<path d="M11.0789247,0.613959159 C12.1313215,0.320210666 13.2724538,0.215046213 14.4997856,0.302172461 L15.3579634,0.363093084 L15.4258568,1.22074743 C15.517777,2.38191799 15.4398502,3.47503096 15.1948246,4.49806664 C15.7105909,5.55574794 16,6.74407641 16,8 C16,12.418278 12.418278,16 8,16 C3.581722,16 0,12.418278 0,8 C0,3.581722 3.581722,0 8,0 C9.09110764,0 10.1311972,0.21843438 11.0789247,0.613959159 Z M6.80355051,9.99957813 L7.69908529,10.9316663 C8.25026463,11.8163069 8.15612509,12.6856831 7.41666667,13.5397949 C9.32634437,12.7370217 10.2464458,11.4553369 10.1769711,9.69474039 C13.2892555,7.6049545 14.7065903,4.80659513 14.4289754,1.29966227 C10.8901455,1.04844659 8.17495912,2.54910084 6.28341642,5.80162501 C4.55451379,5.75586272 3.29337498,6.67664192 2.5,8.56396262 C3.37809245,7.80374262 4.27231852,7.7255871 5.18267822,8.32949606 L5.18803946,8.31812621 L5.94797817,9.1090836 C5.4268151,9.62712653 5.09019249,10.2421732 4.93811035,10.9542236 C5.62997938,10.8040183 6.25179277,10.4858032 6.80355051,9.99957813 Z M2.11116028,11.7908936 L4.31250968,9.56568542 L3.74682426,9 L1.69796753,11.0384064 L2.11116028,11.7908936 Z M2.75532532,12.630661 L3.3177977,13.2048645 L4.67749023,11.7019653 L4.11180481,11.1362799 L2.75532532,12.630661 Z M4.96665955,14.3043518 L6.91250968,12.3656854 L6.34682426,11.8 L4.23538208,13.9064484 L4.96665955,14.3043518 Z" />
		<circle cx="10.4" cy="5.2" r="1.2" />
	</svg>
)

export default Rocket
