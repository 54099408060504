import React from 'react'

const Planet = () => {
  return (
      <div className="super-moon">
        <div className="crater"></div>
        <div className="crater2"></div>
        <div className="crater3"></div>
      </div>
  )
}
export default Planet
